import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const talksData = [
  {
    event: "GraphQL Wrocław",
    city: "Wrocław",
    date: "Jan 2023",
    title: "Subscription Webhooks",
    video: "https://youtu.be/x5e_DOPALpk",
  },
  {
    event: "API Days",
    city: "Paris",
    date: "Dec 2022",
    title: "Subscription Webhooks",
    video: "https://youtu.be/51h0ktrlMjI",
  },
  {
    event: "DjangoCon Europe",
    city: "Remote",
    date: "Sep 2020",
    title: "GraphQL-first Django",
    video: "https://youtu.be/GoGpjngDB7c",
  },
  {
    event: "MeetTech Piła",
    city: "Remote",
    date: "Jul 2020",
    title:
      "Building a GraphQL server in 30 minutes with Python, Ariadne and Starlette (live coding).",
    video: "https://youtu.be/1AsjIDplTtU?t=128",
  },
  {
    event: "GraphQL Hong Kong",
    city: "Remote",
    date: "Mar 2020",
    title: "Real World Graphene",
    video: "https://youtu.be/UVLAClSlzOc",
  },
  {
    event: "API Days",
    city: "Paris",
    date: "Dec 2019",
    title: "Real World Graphene",
    video: "https://youtu.be/QunCEdVp3mg",
  },
  {
    event: "GraphQL Summit",
    city: "San Francisco",
    date: "Oct 2019",
    title: "Real World Graphene",
    video: "",
  },
  {
    event: "PyCon Korea",
    city: "Seoul",
    date: "Aug 2019",
    title: "Real World Graphene",
    video: "https://youtu.be/hDFtMagIGec",
  },
  {
    event: "PyCon Israel",
    city: "Tel Aviv",
    date: "Jun 2019",
    title: "Real World Graphene",
    video: "https://youtu.be/iDqgMbPSUYs",
  },
  {
    event: "PyConWeb",
    city: "Munich",
    date: "May 2019",
    title: "Real World Graphene",
    video: "",
  },
  {
    event: "PyCon Italy",
    city: "Florence",
    date: "May 2019",
    title: "Real World Graphene",
    video: "https://youtu.be/bPWEKKYf17c",
  },
  {
    event: "DjangoCon Europe",
    city: "Copenhagen",
    date: "Apr 2019",
    title:
      "Creating a GraphQL API in Python: from Django to fully asynchronous",
    video: "https://youtu.be/h87F1uRHOYc",
  },
  {
    event: "GraphQL Wrocław",
    city: "Wrocław",
    date: "Jan 2019",
    title: "Why you should migrate to GraphQL in 2019",
    video: "",
  },
  {
    event: "GraphQL Paris",
    city: "Paris",
    date: "Jan 2019",
    title: "Adding a GraphQL layer on top of a large Django app",
    video: "https://youtu.be/EwlTBVDv9TU",
  },
]

const S = {}
S.SpanRow = styled.span`
  display: flex;
  algin-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;

  :hover {
    background-color: #f5f5f5;
  }

  :active {
    background-color: #e5e5e5;
  }
`

S.Li = styled.li`
  border-bottom: 1px solid #e5e5e5;
  :last-child {
    border-bottom: none;
  }
`

const TalkItem = ({ event, date, city = "", title = "", video = "" }) => {
  const spanRow = (
    <S.SpanRow>
      <span
        style={{
          flexShrink: 0,
          alignSelf: "flex-start",
          width: "7.5rem",
          color: "#334155",
        }}
      >
        {date}
      </span>
      <span style={{ flexGrow: 1 }}>
        {title} - {event}
      </span>
      <span style={{ paddingLeft: "1.75rem", color: "#334155" }}>{city}</span>
    </S.SpanRow>
  )

  return (
    <S.Li>
      {video ? (
        <a
          href={video}
          title={`Watch ${title} at ${event}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          {spanRow}
        </a>
      ) : (
        <span>{spanRow}</span>
      )}
    </S.Li>
  )
}

const SpeakingPage = () => {
  const speakingImg = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "speaking.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Speaking" />
      <section>
        <h1>Speaking</h1>
        <p style={{ textAlign: "justify" }}>
          I love sharing my knowledge and speaking at tech conferences and
          events. If you're interested in having me speak at your event, please
          don't hesitate to reach out. Topics I'm passionate about include
          Python, Django, GraphQL, web development, open-source, and e-commerce.
        </p>
        <Img
          style={{ marginTop: "3rem" }}
          fluid={speakingImg.file.childImageSharp.fluid}
          title="Marcin Gębala presenting at PyCon Korea 2019 in Seoul"
          alt="Marcin Gębala presenting at PyCon Korea 2019 in Seoul"
        />
      </section>
      <section>
        <ul>
          {talksData.map((talk, index) => (
            <TalkItem key={index} {...talk} />
          ))}
        </ul>
      </section>
    </Layout>
  )
}

export default SpeakingPage
